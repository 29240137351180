.siguientecomponent-div {
  width: 90vw;
  background-color: white;
  display: flex;
  text-align: center;
  align-items: center;
}
.titulo-trabajamos {
  font-size: 40px;
  overflow-y: hidden;
  color: rgb(0, 0, 0);
  z-index: 1000;
}
.botones-con-titulos {
  padding-left: 10vw;
}

.outside-div-two-sides {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.div-titulo-como-trabajamos {
}

.individual-texto {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.boton-selector {
  transition: 0.5s;
  border-color: black;
  border: solid;
  border-width: 4px;
  border-radius: 100px;
  min-width: 50px;
  min-height: 50px;
  font-weight: 900;
  font-size: 30px;
  margin-right: 2vw;
  cursor: pointer;
  background-color: white;
}

.boton-selector:hover {
  border-color: rgb(255, 255, 255);
  background-color: black;
  color: white;
  border: solid;
}

.boton-selector-selected {
  border-color: rgb(255, 255, 255);
  background-color: black;
  color: white;
  border: solid;
}

.outside-div-right {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible;
  height: 60vh;
  width: 50%;
  background-color: (0, 0, 0, 0);
  background-color: rgb(0, 0, 0);
  overflow: hidden; /* Updated to hide overflow content */
  border-radius: 50%;
}
.outside-div-left {
  opacity: 0;
  width: 40vw;
}

.siguientecomponent-div h2 {
  font-size: 100%;
  text-align: center;
}

.texto-derecha {
  text-align: center;
  width: 80%;
  color: rgb(255, 255, 255);
  font-size: 20px;
}

@keyframes slideInFromLeft {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.sliding-in-left {
  transform: translateX(-100%);
  opacity: 0;
  animation: slideInFromLeft 1s forwards;
}

.sliding-in-right {
  transform: translateX(100%);
  opacity: 0;
  animation: slideInFromRight 1s forwards;
}

.boton-paso-uno {
  margin-top: 3vh;
  width: 50%;
  height: 70px;
  border-width: 3px;
  border-width: 3px;
  border: solid;
  border-color: black;
  background-color: #ffffff;
  border-radius: 50px;
  font-weight: 900;
  font-size: 25px;
  padding: 4px;
  transition: 0.5s;
  cursor: pointer;
  transition: 0.5s;
}

.boton-paso-uno:hover {
  background-color: rgba(0, 0, 0);
  border-color: #000000;
  color: #ffffff;
}

.cuboblanco6 {
  transform: rotate(-35deg);
  left: 70%;
  z-index: -1;
}
.cuboblanco7 {
  transform: rotate(-65deg);
  left: 10%;
  scale: 0.5;
  z-index: -1;
}

.cuboblanco8 {
  transform: rotate(-15deg);
  left: 40%;
  scale: 0.3;
  z-index: -1;
}
.texto-dentro-de-circulos {
  -webkit-text-stroke-color: rgb(0, 0, 0);
  -webkit-text-stroke-width: 1px;
  font-size: 30px;
  z-index: 10;
}

.testing {
  height: 7vh;
  max-height: fit-content;
  background-size: cover; /* This will cover the div without repeating */
  background-position: center; /* Keeps the image centered */
  background-image: url("../images/onda1.png");
  padding-bottom: 0px;
  margin-bottom: 0px;
}

@media (min-width: 1024px) {
  .testing {
    height: 25vh;
    padding-top: 16vh;
    min-height: 300px;
  }
}
@media only screen and (max-width: 700px) {
  .siguientecomponent-div {
    flex-direction: column;
  }

  .titulo-trabajamos {
    z-index: 9;
  }
  .outside-div-two-sides {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .outside-div-right {
    height: 30vh;
  }
  .outside-div-left {
    font-size: 10px;
  }
  .individual-texto {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .boton-selector {
    font-size: 10px;
    height: 30px;
    width: 30px;
    border-width: 2px;
  }
  .boton-paso-uno {
    height: 30px;
    min-width: 50px;
    font-size: 12px;
    border-width: 2px;
  }
  .texto-dentro-de-circulos {
    font-size: 15px;
  }
}
.siguientecomponent-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho total */
}

.div-titulo-como-trabajamos {
  margin-bottom: 20px; /* Asegura espacio entre el título y el contenido siguiente */
}

.titulo-trabajamos {
  font-size: calc(
    16px + 2vw
  ); /* Escala el tamaño del título basado en el ancho de la ventana */
  color: rgb(0, 0, 0);
  z-index: 2; /* Ajusta según sea necesario para mantener el título visible */
}
/* Asegurando la forma circular */
.outside-div-right {
  width: 600px; /* Establece un tamaño fijo o usa porcentajes si deseas más flexibilidad */
  height: 600px; /* Igual al ancho para formar un círculo */
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  border: solid;
  border-color: #e0e0e0;
  border-width: 10px;
  align-items: center;
  overflow: hidden; /* Mantiene todo el contenido dentro del círculo */
}

/* Ajustes para la disposición y tamaño de los elementos en pantallas pequeñas */
@media only screen and (max-width: 700px) {
  .outside-div-two-sides {
    flex-direction: column;
    align-items: center;
  }

  .outside-div-left,
  .outside-div-right {
    width: 100%; /* Ocupa todo el ancho disponible */
    margin-bottom: 20px; /* Espacio entre elementos cuando están en columna */
  }

  .individual-texto,
  .boton-selector,
  .boton-paso-uno,
  .texto-dentro-de-circulos {
    font-size: 22px;
  }
  .boton-paso-uno{
    height: 50px;
  }
  .outside-div-right {
    height: 300px;
    width: 300px;
  }
}
