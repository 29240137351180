.outside-div-footer {
  height: 30vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  color: white;
  justify-content: space-evenly;
  padding-bottom: 10vh;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Las animaciones originales aquí */

/* Nueva clase para cuando el elemento está visible */
.visible {
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.left-side.visible {
  animation-name: slideInFromLeft;
}

.middle-side.visible {
  animation-name: slideInFromBottom;
}

.right-side.visible {
  animation-name: slideInFromRight;
}

/* Asegúrate de que los elementos no sean visibles antes de la animación */
.left-side,
.middle-side,
.right-side {
  opacity: 0;
}

.redes-netriup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}

.button-redes {
  color: rgb(247, 247, 247);
  background-color: black;
  border-style: none;
  cursor: pointer;
}
