.whatsapp-btn-container {
    position: fixed;
    right: 30px;
    opacity: 0;
    bottom: -50px;
    padding: 24px;
    animation: fade-up 1000ms forwards;
    animation-delay: 1000ms;
  }
  
  @keyframes fade-up {
    100% {
      bottom: 24px;
      opacity: 1;
    }
  }
  
  .whatsapp-btn-container .whatsapp-btn {
    font-size: 48px;
    color: #25d366;
    display: inline-block;
    transition: all 400ms;
  
  }
  
  .whatsapp-btn-container .whatsapp-btn:hover {
    transform: scale(1.2);
  }
  
  .whatsapp-btn-container span {
    position: absolute;
    top: 0;
    left: 4px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #075e54;
    transform: rotateZ(20deg) translateX(10px);
    opacity: 0;
    transition: all 400ms;
  
  }
  
  .whatsapp-btn-container .whatsapp-btn:hover + span {
    transform: rotateZ(0deg) translateX(0px);
    opacity: 1;
  }
  
  .whatsapp {
    position: fixed;
    width: 90px;
    height: 90px;
    bottom: 40px;
    right: 60px;
    background-color: #25d366;
    color: #fff;
    border-radius: 90px;
    text-align: center;
    font-size: 45px;
    z-index: 100;
    box-shadow: 2px 2px 3px rgb(76, 73, 73);
  
  }
  
  .whatsapp-icon{
    margin-top: 20px;
    color: rgb(255, 255, 255);
  }
  
  .whatsapp:hover {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @media only screen and (max-width: 700px) {
    .whatsapp {
      position: fixed;
      width: 50px;
      height: 50px;
      bottom: 90px;
      right: 50px;
      background-color: #25d366;
      color: #fff;
      border-radius: 50px;
      text-align: center;
      font-size: 25px;
      z-index: 100;
      box-shadow: 2px 2px 3px rgb(76, 73, 73);
      visibility: hidden;
    
    }
    .whatsapp-icon {
      margin-top: 12px;
    }
  }