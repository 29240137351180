.outside-div-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3vh;
    width: fit-content;
}

.email-button{

    background-color: white;
    color:black;
    font-weight: bold;
}