.outside-div-contacto {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  align-items: center;
  background-color: black;
}

.contacto-title {
  color: rgb(253, 253, 253);
  padding-top: 3vh;
}

.contactos-disponibles-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  color: white;

  border-color: white;
  border-style: solid;
  border-width: 4px;
  border-radius: 100px;
  border-radius: 100px;
  width: 90vw;
  padding: 2vw;
  margin-top: 2vh;
  z-index: 3;
}

.contactos-disponibles-div:hover {
  width: 90vw;
  padding: 2vw;
  margin-top: 2vh;
  z-index: 3;
}
.calendly {
  height: fit-content;
}

.lmtWIHO_gkbTeeyuvoJC {
  overflow-y: hidden !important;
}

.whatsapp-button {
  background-color: green;
  color: white;
  height: 6vh;
  width: 40vh;
  border-style: none;
  border-radius: 100px;
  margin: 6vh;
  padding: 5px;
  font-size: 20px;
  font-weight: bold;
  border-style: solid;
  border-color: white;
  cursor: pointer;
  transition: 0.5s;
}

.whatsapp-button:hover{
  background-color: black;
  color: white;
}

.texto-contacto{
  font-size: 30px;
  font-weight: bold;
}

.calendly{
  overflow: hidden;
  width: 80vw;
  min-height: fit-content;
  margin: 3vh;
}

.email{
  font-weight: 400;
}

.email-section{
  margin: 3vh;
}

.formulario-div-contacto{
  display: flex;
  flex-direction: column;
  align-items: center;
}