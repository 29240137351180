.outside-div-blog {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: black;
}

.blog-title {
  color: rgb(253, 253, 253);
  padding-top: 3vh;
}

.ind-blog {
  display: flex;
  flex-direction: column;
  width: 90vw;
  min-height: 15vh;
  padding: 3vw;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  color: white;
  border-style: solid;
  background-color: rgba(0, 0, 0, 0.5);
  border-color: white;
  margin: 3vh;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  cursor: pointer;
}

.ind-blog:hover {
  background-color: white;
  color: black;
}

.titulo-blogs{
  padding: 2vw;
}