.outside-div-servicios {
  width: 100vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: black;
}

.servicios-title {
  color: rgb(253, 253, 253);
  margin-top: 3vh;
}
.three-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.cards {
  display: flex;
  flex-direction: column;
  color: rgb(0, 0, 0);
  padding: 30px;
  width: 22vw;
  border-style: solid;
  border-color: white;
  border-width: 4px;
  border-radius: 100px;
  height: fit-content;
  min-height: 40vh;
  margin: 1vw;
  background-color: white;
  z-index: 3;
  cursor: pointer;
  transition: 0.3s;
}

.cards:hover{
  background-color: rgba(0, 0, 0, 0.679);
  color: rgb(255, 255, 255);
}
.descripcion-de-servicio{
  padding-top: 3vh;
  font-weight: bold;
}
.titulo-de-servicio{
  font-size: 20px;
  padding: 10px;
}

@media only screen and (max-width: 1000px) {

  .three-cards{
    flex-direction: column;
    margin-top: 3vh;
  }
  .cards{
    justify-content: center;
    width:70vw;
    height: fit-content;
    min-height: 20vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-right: 20vw;
    margin-left: 20vw;
  }
}