.header-div {
  height: max-content;
}
.out-div-nav-bar {
  background-color: #000000;
  height: 60px;
}

.navigation-bar {
  height: 60px;
}

.navigation-bar {
  z-index: 10;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  left: 0;
  width: 100vw; /* Ensure it extends full width */
}

.menu {
  display: flex;
  justify-content: space-evenly;
  align-items: center; /* Vertically center the text */
  height: 60px; /* Adjust the height of the parent container as needed */
  width: 75%;
  padding-left: 10%;
  padding-right: 10%;
}

.menu-text {
  z-index: 3;
  padding-left: 2vw;
  padding-right: 2vw;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center; /* Center text horizontally */
  height: 60px; /* Set the desired height for .menu-text */
  line-height: 60px; /* Vertically center text within .menu-text */
}

.menu-text-responsive {
  z-index: 3;
  padding-left: 2vw;
  padding-right: 2vw;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center; /* Center text horizontally */
  height: fit-content; /* Set the desired height for .menu-text */
  line-height: fit-content; /* Vertically center text within .menu-text */
}

.menu-text:hover {
  background-color: white;
  color: #000000;
}

header .nav-btn {
  visibility: hidden;
  opacity: 0;
  height: 0vh;
  z-index: -2;
}

.whatsapp-icon-navbar {
  color: white;
  text-align: center;
}

.whatsapp-icon-menu {
  font-size: 1.8rem;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
}

.whatsapp-icon-menu:hover {
  color: green;
}

.nav-btn-div {
  width: 0%;
  height: 0%;
}
.barra-navegacion-responsive{
  height: 0px;
  visibility: none;
}
@media only screen and (max-width: 1024px) {
  .out-div-nav-bar{
    height: 0px;
  }
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    width: 100vw;
    font-size: xxx-large;
    z-index: 9;
    color: #ffffff;
    height: fit-content;
    background-color: rgba(0, 0, 0);
    border: none;
  }
  .navigation-bar {
    visibility: hidden;
  }
  .barra-navegacion-responsive {
    visibility: visible;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.881);
    border-color: rgb(255, 255, 255);
    border-color: rgb(255, 215, 0);
    border-style: solid;
    border-width: 1px;
    height: 10vh;
    width: 90vw;
    position: fixed;
    bottom: 20px;
    border-radius: 100px;
    z-index: 10;
  }
  .menu .responsive_nav {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 5vh;
    padding: none;
    height: 100vh;
  }
  .nav-btn .nav-close-btn {
    padding: 0px;
    margin: 0px;
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding-left: 0px;
    padding-right: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 10;
  }
  .navigation-bar a {
    font-size: 1rem;
  }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    font-size: 0.1rem;
    max-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #000000;
    transition: 0.5s;
    transform: translateY(-100vh);
    width: 100vw;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    display: flex;
    text-align: center;
    align-self: center;
    position: absolute;
    top: 1.5rem;
    padding: 0px;
    margin: 0px;
    align-items: center;
  }
}

@media only screen and (max-width: 500px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    color: rgb(255, 255, 255);
    background-color: #00000000;
    padding-right: 0px;
    padding-left: 0px;
  }

  .whatsapp-icon-navbar {
    font-size: 30px;
  }
  nav .nav-close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1.5rem; /* Adjust or remove as needed */
  }
  .menu {
    background-color: #000000;
    height: 100vh;
    width: 100vw;
    text-align: center;
    z-index: 10;
  }
  .nav-btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
  .menu .responsive_nav {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100vw;
    padding-top: 5vh;
    padding: none;
  }
  .menu {
    padding-left: 0px;
    padding-right: 0px;
  }
  .menu-text {
    transition: 0.5s;
    width: 100vw;
  }
  .menu-text-responsive {
    transition: 0.5s;
    width: 100vw;
    border-radius: 50px;
    padding: 1px;
  }
  .menu-text-responsive{
    border-color: white;
  }
  .menu-text:hover {
    background-color: white;
  }
}
