.otrocomponent-div {
  padding-top: 3vh;
  display: flex;
  width: 100vw;
  flex-direction: column;
  text-align: center;
  background-color: #210002;
  height: fit-content;
  margin-top: 0vw;
  color: rgb(255, 255, 255);
  justify-content: center;

  background-color: rgb(0, 0, 0);
}
.testing {
  padding-top: 15vh;
  max-height: fit-content;
  background-size: cover; /* This will cover the div without repeating */
  background-position: center; /* Keeps the image centered */
  background-image: url("../images/onda1.png");
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.parrafo-netriup {
  width: 70vw;
  text-align: center;
  font-size: 30px;
  z-index: 3;
}

.informacion-texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.frase-netriup {
  width: 70vw;
  font-size: 60px;
  margin-bottom: 5vh;
  z-index: 3;
}
.highlight {
  color: #ffd700; /* Golden yellow */
}
.boton-contactarme {
  margin-top: 3vh;
  width: 33%;
  height: 70px;
  border-width: 3px;
  border-width: 3px;
  border: solid;
  border-color: black;
  background-color: #ffd700;
  border-radius: 50px;
  font-weight: 900;
  font-size: 25px;
  padding: 4px;
  transition: 0.5s;
  cursor: pointer;
}

.boton-contactarme:hover {
  background-color: rgba(255, 255, 255, 0);
  border-color: #ffd700;
  color: #ffd700;
}

@media only screen and (max-width: 500px) {
  .frase-netriup {
    font-size: 40px;
    padding: 2px;
    width: 80vw;
  }
  .parrafo-netriup {
    font-size: 22px;
    width: 80vw;
  }
  .boton-contactarme{
    width: 80vw;
  }


}
