.top-netriup {
  padding-top: 20px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-image: url("../images/test.png");
  background-color: black;
  position: relative;
  width: 200vw;
  overflow: hidden;
  left: -50px;
  min-width: 1000px;
}

.bottom-netriup {
  padding-top: 20px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-image: url("../images/test.png");
  background-color: black;
  position: relative;
  width: 200vw;
  overflow: hidden;
  z-index: 2;
  min-width: 2000px;
  padding-left: 50vw;
  right: 800px;
  margin-bottom: -1px;
}

.netriup-banner {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 80px;
  align-items: center;
  background-color: black;
  color: white;
  height: 60vh;
  justify-content: center;
  width: 100vw;
  background-image: url("../images/test2.png");
  margin-top: -1px;
  margin-bottom: -1px;
}

.titulo-netriup {
  font-size: 80px; /* Default font size */
  z-index: 3;
}

@media (min-width: 600px) {
  .titulo-netriup {
    z-index: 3;

    font-size: 100px; /* Slightly larger font size for tablets and above */
  }
}

@media (min-width: 1024px) {
  .titulo-netriup {
    z-index: 3;
    font-size: 150px; /* Larger font size for desktops */
  }
  .top-netriup {
    left: -200px;
  }
}
@media (max-width: 600px) {
  .cuboblanco4 {
    scale: 0.5;
  }
}
.cuboblanco1 {
  z-index: 0;
  transform: rotate(20deg);
  left: 30vw;
  top: 20px;
  scale: 0.8;
}

.cuboblanco2 {
  transform: rotate(10deg);
  left: 20px;
  scale: 0.4;
}

/* Targeting the second cuboblanco image */
.cuboblanco {
  transform: rotate(-20deg);
  right: 2vw;
  top: 20vh;
}

.cuboblanco4 {
  transform: rotate(-35deg);
  left: 7vw;
  top: 400px;
}
