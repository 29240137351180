.testing-dos {
  height: 7vh;
  max-height: fit-content;
  background-size: cover; /* This will cover the div without repeating */
  background-position: center; /* Keeps the image centered */
  background-image: url("../images/onda1.png");
  padding-bottom: 15vh;
  margin-bottom: 0px;
  transform: rotate(180deg);
  z-index: -3;
  margin-top: -1px;
  margin-bottom: -1px;
}

.outside-div-trabajar-con-nosotros {
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding: 0px;
  background-color: black;
  height: fit-content;
  width: 100vw;
  padding: -10px;
}
.div-texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 10vh;
  height: 50vh;
}

.titulo-trabajar-con-nosotros {
  color: white;
  background-color: rgb(0, 0, 0);
  width: 99vw;
  text-align: center;
  position: absolute;
  z-index: 1 !important;
  padding-top: 3vh;
}
.beneficio-texto {
  color: rgb(0, 0, 0);
}
.beneficios-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;
  width: 80vw;
  height: 100vh;
  background-color: white;
  
  border-radius: 100px;
  margin: 5vh;
  padding: 2vh;
  
}
.boton-div-trabajar{
  display: flex;
  width: 100vw;
  justify-content: center;
}
@media (min-width: 1024px) {
  .testing-dos {
    height: 25vh;
    padding-top: 15vh;
    padding-bottom: 0vh;
    min-height: 300px;
  }
}