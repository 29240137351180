.container-blog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: black;
}
.ind-blog-result {
    margin-top: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-radius: 100px;
  border-width: 4px;
  background-color: rgba(0, 0, 0, 0.8);
  width: 90vw;
  height: fit-content;
  border-color: white;
  padding: 2vw;
  z-index: 1;
  text-align: center;
}

.parrafo-blog {
  color: rgb(255, 255, 255);
  padding: 5vw;
  line-height: 115%;
}
.blog-titulo {
  color: white;
  padding: 4vw;


}
.subtitulo-blog{
  min-height:30px;
  height: fit-content;

}
